<template>
  <div>
    <div
      v-if="label"
      class="gl-input__label mb-2"
    >
      {{ label }}
    </div>
    <vSelect
      v-if="hasMultyCoin"
      v-model="coin"
      class="coin-select-base mr-2 m-mr-0"
      :class="[{'coin-select-base__dark': dark}, { 'coin-select-base__small' : small}, { 'coin-select-base__slim' : slim}, {'coin-select-base__bordered' : bordered}]"
      :clearable="false"
      :disabled="disabled"
      label="label"
      :options="currencyList"
      :searchable="false"
      @input="handleCoinSelect"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {mapMutations, mapState} from "vuex";
import config from "@/utils/appConfig";

export default {
  components: {
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    iconHeight: {
      type: Number,
      default: 16,
    },
    iconWidth: {
      type: Number,
      default: 16,
    },
    iconAfter: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    slim: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    padder: {
      type: Boolean,
      default: false,
    },
    getOnlyData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      coin: {
        key: 'btc',
        path: 'tx',
        label: 'BTC'
      },
    }
  },
  computed: {
    hasMultyCoin() {
      return config.VUE_APP_COIN_TYPE
    },
    ...mapState('analytics', ['coinType', 'currencyList'])
  },
  watch: {
    coinType: {
      handler(coin) {
        this.SET_COIN_TYPE(coin)
        this.coin = this.currencyList.find(item => item.key === coin) || this.currencyList[0]
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations({
      SET_COIN_TYPE: 'analytics/SET_COIN_TYPE',
      SET_COIN_DATA: 'analytics/SET_COIN_DATA',
    }),
    handleCoinSelect(data) {
      if (!this.getOnlyData) {
        this.SET_COIN_TYPE(data.key)
        this.SET_COIN_DATA(data)
      }
      this.$emit('change', data)
    },
  },
}
</script>

<style>
.coin-select-base .vs__dropdown-toggle {
  background: #fff;
  border: 1px solid #f5f5f5 !important;
  min-height: 38px;
  min-width: 150px !important;
}

.coin-select-base__dark .vs__dropdown-toggle {
  background: var(--cotton-grey-f-5);
  border: 1px solid var(--cotton-grey-f-5) !important;
  min-height: 38px;
  min-width: 150px !important;
}

.coin-select-base__small .vs__dropdown-toggle{
  min-height: 32px !important;
}

.coin-select-base__slim .vs__dropdown-toggle{
  min-width: 80px !important;
}

.coin-select-base__bordered .vs__dropdown-toggle{
  border: 1px solid var(--soft-blue) !important;
}

@media (max-width: 767px) {
  .coin-select-base__dark .vs__dropdown-toggle {
    min-height: 32px;
    padding: 0 !important;
  }
  .coin-select-base .vs__dropdown-toggle {
    min-height: 32px;
    padding: 0 !important;
  }
  .coin-select-base__slim .vs__selected-options {
    flex-wrap: unset;
  }
}
</style>
