<template>
  <div class="pp-my-cases__header">
    <div class="pp-my-cases__header-search">
      <div class="pp-my-cases__header-title">
        Search by case name
      </div>
      <gl-search-box
        v-model="search"
        button-text="Search"
        dark-clear
        :disabled="!search"
        grey
        height="32px"
        :independent="isMobileInclude ? true : false"
        is-search
        :min-width="isMobile ? '150px' : '250px'"
        placeholder="Enter the case name"
        @clear="clearSearching"
        @search="loadData"
      />
    </div>
    <div class="pp-my-cases__header-filter">
      <div class="pp-my-cases__header-title">
        Filter by date & time
      </div>
      <date-picker
        ref="datePicker"
        v-model="transactionsListDates"
        class="cases-date-picker"
        format="YYYY-MM-DD HH:mm:ss"
        placeholder="Select datetime range"
        range
        :show-time-panel="showTimeRangePanel"
        type="datetime"
        @clear="handleDateClose"
        @close="handleRangeClose"
      >
        <template v-slot:footer>
          <div class="flex space-between">
            <div>
              <button
                class="mx-btn mx-btn-text link"
                @click="toggleTimeRangePanel"
              >
                {{ showTimeRangePanel ? 'Select date' : 'Select time' }}
              </button>
            </div>
            <div>
              <button
                class="mx-btn mx-btn-text cancel-date-range mr-2"
                @click="handleDateClose"
              >
                Cancel
              </button>
              <button
                class="mx-btn mx-btn-text apply-date-range"
                @click="setDate(transactionsListDates)"
              >
                Apply
              </button>
            </div>
          </div>
        </template>
      </date-picker>
    </div>
    <div class="pp-my-cases__header-coin-select">
      <div class="pp-my-cases__header-title">
        Blockchain
      </div>
      <GlCoinSelect
        dark
        small
        @change="loadData"
      />
    </div>
    <div class="pp-my-cases__header-cntrls">
      <template v-if="isArchive">
        <div 
          v-if="selectedIds.length > 0"
          class="pp-my-cases__header-cntrls-item"
          @click="reopenSelectedCase"
        >
          <ReopenIcon />
          <div class="pp-my-cases__header-cntrls-item-tooltip">
            Reopen selected
          </div>
        </div>
        <div 
          class="pp-my-cases__header-cntrls-item"
          :class="{ 'pp-my-cases__header-cntrls-item--not-selected' : selectedIds.length === 0 }"
          @click="selectDeselectAll"
        >
          <div class="pp-my-cases__header-cntrls-item-checkbox" />
          <div v-if="selectedIds.length === 0">
            Select all
          </div>
          <div class="pp-my-cases__header-cntrls-item-tooltip">
            Deselect all
          </div>
        </div>
        <div 
          v-if="selectedIds.length > 0"
          class="pp-my-cases__header-cntrls-item"
          @click="showRemoveSelectedModal"
        >
          <DeleteIcon />
          <div class="pp-my-cases__header-cntrls-item-tooltip">
            Delete selected
          </div>
        </div>
      </template>
      <template v-else>
        <div 
          v-if="selectedIds.length > 0"
          class="pp-my-cases__header-cntrls-item"
          @click="openArchiveConfirmModal"
        >
          <ArchiveIcon />
          <div class="pp-my-cases__header-cntrls-item-tooltip">
            Archive selected
          </div>
        </div>
        <div 
          class="pp-my-cases__header-cntrls-item"
          :class="{ 'pp-my-cases__header-cntrls-item--not-selected' : selectedIds.length === 0 }"
          @click="selectDeselectAll"
        >
          <div class="pp-my-cases__header-cntrls-item-checkbox" />
          <div v-if="selectedIds.length === 0">
            Select all
          </div>
          <div class="pp-my-cases__header-cntrls-item-tooltip">
            Deselect all
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
//Component
import GlSearchBox from '@/components/gl-search-box'
import ArchiveIcon from '@/pages/cases/components/svg/ArchiveIcon'
import ReopenIcon from '@/pages/cases/components/svg/ReopenIcon'
import DeleteIcon from '@/pages/cases/components/svg/DeleteIcon'
import GlCoinSelect from "@/components/gl-coin-select.vue"

// Utils
import moment from "moment"

// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  name: 'MyCasesHeader',
  components:{
    GlCoinSelect,
    GlSearchBox,
    ArchiveIcon,
    ReopenIcon,
    DeleteIcon,
  },
  mixins: [deviceWidthMixin],
  props: {
    selectedIds: {
      type: Array,
      default: () => [],
    },
    activeTab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      search: '',
      transactionsListDates: [],
      showTimeRangePanel: false,
      from: null,
      to: null,
    }
  },
  computed: {
    isArchive() {
      return this.activeTab == 'archive'
    }
  },
  methods: {
    moment,
    clearSearching() {
      this.search = ''
      this.loadData()
    },
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel
    },
    toSeconds(date, formatDate = 'YYYY-MM-DD HH:mm') {
      return moment(date, formatDate).unix()
    },
    handleRangeClose() {
      this.showTimeRangePanel = false
    },
    handleDateClose() {
      this.transactionsListDates = []
      this.from = undefined
      this.to = undefined
      this.$refs.datePicker.closePopup()
      this.loadData()
    },
    setDate(date) {
      const [from, to] = date

      if (from && to) {
        this.from = this.toSeconds(from) * 1000
        this.to = this.toSeconds(to) * 1000
        this.$refs.datePicker.closePopup()
        this.loadData()
      } else this.clearDate()
    },
    clearDate() {
      this.transactionsListDates = []
      this.from = undefined
      this.to = undefined
      this.loadData()
    },
    loadData() {
      this.$emit('emitedData', {
        search: this.search,
        transactionsListDates: this.transactionsListDates,
        from: this.from,
        to: this.to,
      })
    },
    openArchiveConfirmModal() {
      if(this.selectedIds.length > 1) {
        this.$emit('openArchiveConfirmModal')
      } else {
        this.$emit('openArchiveCaseConfirmModal', this.selectedIds[0])
      }
    },
    selectDeselectAll() {
      this.$emit('selectDeselectAll')
    },
    reopenSelectedCase() {
      this.$emit('reopenSelectedCase')
    },
    showRemoveSelectedModal() {
      if(this.selectedIds.length > 1) {
        this.$emit('showRemoveSelectedModal')
      } else {
        this.$emit('openRemoveModal', this.selectedIds[0])
      }
    }
  }
}
</script>

<style scoped>
.pp-my-cases__header {
  display: flex;
  align-items: flex-end;
  gap: 24px;
  margin-bottom: 24px;
}
.pp-my-cases__header-title {
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 1.334;
}
.pp-my-cases__header-search {
  max-width: clamp(400px, 42%, 580px);
  min-width: clamp(400px, 42%, 580px);
}
.pp-my-cases__header-search::v-deep .gl-search-box {
  gap: 16px;
}
.pp-my-cases__header-search::v-deep .gl-search-box .gl-input__input {
  min-width: 100px !important;
  border-radius: 6px;
}
.pp-my-cases__header-search::v-deep .gl-search-box .gl-button {
  width: 140px;
}
.pp-my-cases__header-filter {
  max-width: clamp(200px, 32%, 440px);
  min-width: clamp(200px, 32%, 440px);
}
.pp-my-cases__header-filter::v-deep .mx-input {
  height: 32px;
  background-color: var(--cotton-grey-f-5) !important;
  border: 1px solid var(--cotton-grey-f-5);
  border-radius: 6px;
  box-shadow: none;
}
.pp-my-cases__header-coin-select {
  max-width: 116px;
  min-width: 116px;
}
.pp-my-cases__header-coin-select::v-deep .v-select {
  margin-right: 0 !important;
  border-radius: 6px;
}
.pp-my-cases__header-coin-select::v-deep .vs__dropdown-toggle {
  height: 32px;
  border-radius: 6px;
}
.pp-my-cases__header-coin-select::v-deep .coin-select-base__dark .vs__dropdown-toggle {
  min-width: 0 !important;
}
.pp-my-cases__header-cntrls {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 32px;
  margin-left: auto;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: var(--space-cadet);
}
.pp-my-cases__header-cntrls-item {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
}
.pp-my-cases__header-cntrls-item-checkbox {
  width: 20px;
  height: 20px;
  position: relative;
}
.pp-my-cases__header-cntrls-item-checkbox::after {
  content: "";
  width: 14px;
  height: 14px;
  border: 1px solid var(--dark-grey-6-e);
  border-radius: 2px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.pp-my-cases__header-cntrls-item-checkbox::before {
  content: "";
  opacity: 1;
  width: 8px;
  height: 1px;
  background-color: var(--dark-grey-6-e);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease;
}
.pp-my-cases__header-cntrls-item-tooltip {
  opacity: 0;
  padding: 8px 12px;
  background-color: var(--white);
  border-radius: 3px;
  box-shadow: 0px 4px 16px 0px #0915401A;
  white-space: nowrap;
  position: absolute;
  right: -30px;
  top: calc(100% + 4px);
  transition: opacity 0.3s ease;
  pointer-events: none;
}
.pp-my-cases__header-cntrls-item:hover:not(.pp-my-cases__header-cntrls-item--not-selected) .pp-my-cases__header-cntrls-item-tooltip {
  opacity: 1;
}
.pp-my-cases__header-cntrls-item--not-selected .pp-my-cases__header-cntrls-item-checkbox::before {
  opacity: 0;
}

@media (max-width: 1399px) {
  .pp-my-cases__header {
    gap: 16px;
    flex-wrap: wrap;
  }
  .pp-my-cases__header-search {
    max-width: calc(52% - 8px);
    min-width: calc(52% - 8px);
  }
  .pp-my-cases__header-filter {
    max-width: calc(48% - 8px);
    min-width: calc(48% - 8px);
  }
  .pp-my-cases__header-cntrls {
    margin-left: 0;
  }
}
@media (max-width: 1023px) {
  .pp-my-cases__header-search {
    order: 1;
    max-width: calc(100% - 200px);
    min-width: calc(100% - 200px);
  }
  .pp-my-cases__header-filter {
    order: 3;
    max-width: calc(100% - 200px);
    min-width: calc(100% - 200px);
  }
  .pp-my-cases__header-coin-select {
    order: 2;
  }
  .pp-my-cases__header-cntrls {
    order: 4;
  }
}
@media (max-width: 767px) {
  .pp-my-cases__header-search {
    max-width: calc(100% - 16px);
    min-width: calc(100% - 16px);
    background-color: var(--white);
    position: absolute;
    top: -100px;
    left: 8px;
  }
  .pp-my-cases__header-search .pp-my-cases__header-title {
    display: none;
  }
  .pp-my-cases__header-filter {
    order: 1;
    max-width: 100%;
    min-width: 100%;
  }
  .pp-my-cases__header-coin-select {
    order: 2;
  }
  .pp-my-cases__header-cntrls {
    order: 3;
  }
}
</style>