<template>
  <div
    class="pp-my-cases__item"
    :class="{ 'pp-my-cases__item--selected': item.selected }"
    @click="updateSelected()"
    @dblclick.stop="openCase()"
  >
    <div class="pp-my-cases__item-img">
      <img
        :alt="item.title"
        :src="`data:image/png;base64, ${item.image}`"
      >
      <div class="pp-my-cases__item-checkbox" />
    </div>
    <div class="pp-my-cases__item-title">
      <div class="pp-my-cases__item-title-text">
        {{ item.title }}
      </div>
      <div class="pp-my-cases__item-title-cntrls">
        <div class="pp-my-cases__item-title-cntrls-edit">
          <gl-icon
            :height="24"
            name="edit-block-active"
            :width="24"
            @click.stop="openEditModal()"
          />
        </div>
        <div
          v-click-outside="onClickOutside"
          class="pp-my-cases__item-title-cntrls-context"
        >
          <gl-icon
            class="cases-context-menu__icon"
            :height="24"
            name="more"
            :width="24"
            @click.stop="openContextMenu()"
          />
          <div
            v-if="showContextMenu"
            class="pp-my-cases__item-title-cntrls-context-menu"
          >
            <template v-if="isArchive">
              <div
                class="pp-my-cases__item-title-cntrls-context-menu-item"
                @click.stop="reopenCase()"
              >
                <gl-icon
                  :height="24"
                  name="refresh"
                  :width="24"
                />
                <div class="pp-my-cases__item-title-cntrls-context-menu-item-text">
                  Reopen
                </div>
              </div>
              <div
                class="pp-my-cases__item-title-cntrls-context-menu-item"
                @click.stop="openRemoveModal()"
              >
                <gl-icon
                  :height="24"
                  name="delete"
                  :width="24"
                />
                <div class="pp-my-cases__item-title-cntrls-context-menu-item-text">
                  Delete
                </div>
              </div>
            </template>
            <template v-else>
              <div
                class="pp-my-cases__item-title-cntrls-context-menu-item"
                @click.stop="openCase()"
              >
                <gl-icon
                  :height="24"
                  name="open-case"
                  :width="24"
                />
                <div class="pp-my-cases__item-title-cntrls-context-menu-item-text">
                  Open
                </div>
              </div>
              <div
                class="pp-my-cases__item-title-cntrls-context-menu-item"
                @click.stop="openCopyCase()"
              >
                <gl-icon
                  :height="24"
                  name="open-copy"
                  :width="24"
                />
                <div class="pp-my-cases__item-title-cntrls-context-menu-item-text">
                  Open Copy
                </div>
              </div>
              <div
                class="pp-my-cases__item-title-cntrls-context-menu-item"
                @click.stop="openArchiveCaseConfirmModal()"
              >
                <gl-icon
                  :height="20"
                  name="folder-red"
                  :width="20"
                />
                <div class="pp-my-cases__item-title-cntrls-context-menu-item-text">
                  Archive Case
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="pp-my-cases__item-info">
      <div class="pp-my-cases__item-info-block">
        <div class="pp-my-cases__item-info-block-header">
          Started
        </div>
        <div class="pp-my-cases__item-info-block-text">
          {{ item.createdAt ? formatDate(item.createdAt, 'dd.MM.yyyy') : null }}
        </div>
      </div>
      <div class="pp-my-cases__item-info-block">
        <div class="pp-my-cases__item-info-block-header">
          Last opened
        </div>
        <div class="pp-my-cases__item-info-block-text">
          {{ item.lastOpened ? formatDate(item.lastOpened, 'dd.MM.yyyy') : null }}
        </div>
      </div>
      <div class="pp-my-cases__item-info-block">
        <div class="pp-my-cases__item-info-block-header">
          Blockchain
        </div>
        <div class="pp-my-cases__item-info-block-text">
          <MyCaseBlockchainIcon 
            :blockchain-icon="item.blockchain"
          />
          {{ item.blockchain }}
        </div>
      </div>
      <div class="pp-my-cases__item-info-block">
        <div class="pp-my-cases__item-info-block-header">
          Starting point
        </div>
        <div class="pp-my-cases__item-info-block-text pp-my-cases__desktop-view">
          {{ item.search }}
        </div>
        <div class="pp-my-cases__item-info-block-text pp-my-cases__tablet-view">
          {{ trancateString(item.search, 6) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// components
import GlIcon from '@/components/gl-icon'
import MyCaseBlockchainIcon from '@/pages/cases/components/MyCaseBlockchainIcon'

// utils
import { formatDate } from "@/utils/format-date"
import { trancateString } from "@/utils/text-formatter"
import vClickOutside from 'v-click-outside'

export default {
  name: 'MyCasesItem',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    GlIcon,
    MyCaseBlockchainIcon,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    activeTab: {
      type: String,
      default: '',
    },
    contextMenuSwitcher: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      showContextMenu: false,
    }
  },
  computed: {
    isArchive() {
      return this.activeTab == 'archive'
    }
  },
  watch: {
    contextMenuSwitcher(newValue) {
      if(this.showContextMenu && newValue != this.item._id) {
        this.showContextMenu = false
      }
    }
  },
  methods: {
    formatDate,
    trancateString,
    updateSelected() {
      this.$emit('updateSelected')
    },
    openCase() {
      this.showContextMenu = false
      this.$emit('openCase')
    },
    openEditModal() {
      this.showContextMenu = false
      this.$emit('openEditModal')
    },
    openCopyCase() {
      this.showContextMenu = false
      this.$emit('openCopyCase')
    },
    openArchiveCaseConfirmModal() {
      this.showContextMenu = false
      this.$emit('openArchiveCaseConfirmModal')
    },
    reopenCase() {
      this.showContextMenu = false
      this.$emit('reopenCase')
    },
    openRemoveModal() {
      this.showContextMenu = false
      this.$emit('openRemoveModal')
    },
    openContextMenu() {
      this.showContextMenu = !this.showContextMenu

      if(this.showContextMenu) {
        this.$emit('closeContextMenu')
      }
    },
    onClickOutside() {
      this.showContextMenu = false
    },
  },
}
</script>

<style scoped>
.pp-my-cases__item {
  flex: 0 0 auto;
  padding: 16px 16px 24px 16px;
  border: 1px solid var(--cotton-grey-f-5);
  border-radius: 8px;
  transition: border 0.3s ease, box-shadow 0.3s ease;
}
.pp-my-cases__item--selected {
  border: 1px solid var(--dark-grey-d-3);
  box-shadow: 0px 2px 8px 1px #4B4B4B0D, 0px 2px 4px -2px #4B4B4B1F;
}
.pp-my-cases__item-img {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 380 / 178;
  margin-bottom: 24px;
  padding: 16px;
  border: 1px solid var(--cotton-grey-f-5);
  border-radius: 4px;
  position: relative;
  user-select: none;
}
.pp-my-cases__item-img img {
  max-width: 100%;
  max-height: 100%;
  position: relative;
  z-index: 1;
}

.pp-my-cases__item-checkbox {
  opacity: 0;
  width: 14px;
  height: 14px;
  border: 1px solid var(--soft-blue);
  border-radius: 2px;
  position: absolute;
  top: 14px;
  right: 14px;
  z-index: 3;
  transition: opacity 0.3s ease;
}
.pp-my-cases__item-checkbox::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: var(--soft-blue);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.pp-my-cases__item--selected .pp-my-cases__item-checkbox {
  opacity: 1;
}

.pp-my-cases__item-title {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: end;
  gap: 24px;
  margin-bottom: 12px;
}
.pp-my-cases__item-title-text {
  margin-bottom: 1px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pp-my-cases__item-title-cntrls {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
}
.pp-my-cases__item-title-cntrls-edit {
  cursor: pointer;
}
.pp-my-cases__item-title-cntrls-context {
  position: relative;
  cursor: pointer;
}
.pp-my-cases__item-title-cntrls-edit svg,
.pp-my-cases__item-title-cntrls-context svg {
  display: block;
}
.pp-my-cases__item-title-cntrls-context-menu {
  padding: 8px 12px;
  background-color: var(--white);
  border-radius: 3px;
  box-shadow: 0px 4px 16px 0px #0915401A;
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
}
.pp-my-cases__item-title-cntrls-context-menu-item {
  display: flex;
  align-items: center;
  gap: 8px;
}
.pp-my-cases__item-title-cntrls-context-menu-item + .pp-my-cases__item-title-cntrls-context-menu-item {
  margin-top: 10px;
}
.pp-my-cases__item-title-cntrls-context-menu-item-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: var(--space-cadet);
  white-space: nowrap;
}
.pp-my-cases__item-title-cntrls-context-menu-item:last-child {
  padding-top: 10px;
  border-top: 1px solid var(--cotton-grey-f-5);
}
.pp-my-cases__item-title-cntrls-context-menu-item:last-child .pp-my-cases__item-title-cntrls-context-menu-item-text {
  color: var(--lipstick);
}

.pp-my-cases__item-info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 16px 12px;
}
.pp-my-cases__item-info-block:nth-child(4) {
  grid-column: span 3;
}
.pp-my-cases__item-info-block-header {
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.334;
  color: var(--dark-grey-6-e);
}
.pp-my-cases__item-info-block-text {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.429;
  color: var(--space-cadet);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1399px) {
  .pp-my-cases__item-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }
  .pp-my-cases__item-info-block:nth-child(4) {
    grid-column: span 1;
  }
}
</style>